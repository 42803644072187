.delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.delete-title {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 0 10px black;
      letter-spacing: 2px;
      color: white;
      /* box-shadow: 0 0 10px rgba(255, 223, 0, 0.8);  */
      background: linear-gradient(180deg, white, gold);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-stroke-width: .4px;
      background-clip: text;
      font-size: 25px;
}

.delete-input {
   margin-bottom: 15px;
   width: 12rem;
}

.delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 125px;
    height: 35px;
    border-radius: 8px;
    background-color: black;
    color: gold;
    font-weight: bold;
    box-shadow: 1px 1px 1px black;
    margin-bottom: 8px;
}

.delete-btn:hover {
    background-color: hsl(36, 61%, 58%) ;
    color: black;
    
}

@media screen and (min-width: 600px) {
    .delete-input {
        font-size: 18px
    }

    .delete-btn {
        font-size: 18px;
        width: 150px;
        height: 40px
    }
}

@media screen and (min-width: 945px) {
    .delete-input {
        font-size: 20px
    }

    .delete-btn {
        font-size: 20px;
        width: 175px;
        height: 45px
    }
}