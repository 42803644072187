.services-overview {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    border: 2px solid hsl(36, 61%, 58%);
    border-radius: 8px;
    /* overflow-y: auto; */
    overflow-x: hidden;
}

.services-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* margin-left: 4px; */
    /* background-color: black;
    border: 2px solid hsl(36, 61%, 58%);
    border-radius: 8px; */
}

.services-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: hsl(36, 61%, 58%);
    margin: 0;margin-bottom: 4px;
}

.scissors {
margin-bottom: 1px;
width: auto;
height: 50px;
}

.shave {
margin-top: 8px;
width: auto;
height: 50px
}

.faceMask {
margin-top: 8px;
width: auto;
height: 50px;
font-weight: bold;
}

.services-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 1px;
    margin-bottom: 1px;
    text-align: center;
    color: hsl(36, 61%, 58%);
    white-space: nowrap;
}

/* .bold-price {
    display: inline;
    font-weight: bold;
} */

.services-list-warn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    color: white;
    width: 100%;
    margin:0;
    text-align: center;
}

@media screen and (min-width: 600px) {
    .scissors{
        height: 60px;
    }

    .shave {
        height: 60px;
    }

    .faceMask {
        height: 60px;
    }

    .services-list {
        font-size: 18px;
    }

    .services-list-warn {
        font-size: 18px;
    }
}

@media screen and (min-width: 945px) {

    .services-overview {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 50%;
        margin-left: 2px;
    }
    
    .services-container {
        justify-content: space-between;
        align-items: flex-start;
        width: 50vw;
        /* transform: translateX(5px); */
      }

    .services-title {
        align-items: center; /* Align items to the left */
        width: 50vw;
    }

    .scissors{
        height: 70px;
        align-self: center;
    }

    .shave {
        height: 70px;
        align-self: center;
    }

    .faceMask {
        height: 70px;
        align-self: center;
    }

    .services-list {
        align-items: center; /* Align items to the left */
        width: 50vw;
        font-size: 20px;
    }

    .services-list-warn {
        align-items: center; /* Align items to the left */
        width: 50vw;
        font-size: 20px;
        margin-top: 4px
    }
  


}

@media screen and (min-width: 1200px) {}