body {
    margin-top: 55px; /* Adjust this value based on your fixed header height */
  }
  
.Header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: black;
    padding-bottom: 5px;
    height: 50px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    border-bottom: 2px solid hsl(36, 61%, 58%);
}

.navBar {
    width: 100%;
    margin: 0;
    padding: 0;
}

.dropDown {
    position: absolute;
    top: 40px;
    right: 0;
    padding: 10px;
    background-color: black;
    border: 1px solid hsl(36, 61%, 58%);
    border-radius: 15px;
    margin-top: 3px;
}

.dropDown::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 13px;
    transform: rotate(45deg);
    border: 10px solid transparent;
    background-color: black;
    border-left: 1px solid gold;
    border-top: 1px solid gold;
}


.menuIcon {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 30px;
    margin-right: 5px;
    color: hsl(36, 61%, 58%);
    cursor: pointer;
}

.menuBtnList li {
    border-block-end: 1px solid hsl(36, 61%, 58%);
    width: 118%;
    text-align: center;
    transition: background-color 0.3s;
    text-transform: uppercase;
    font-weight: bold;
}

.menuBtnList li:last-child {
    border: none;
}

.menuBtnList li:is(:hover, :focus) {
    color: white; /* Change the background color on hover */
}

.headerBtnList li:is(:hover, :focus) {
    color: white; /* Change the background color on hover */
}


.headerBtn {
    color: hsl(36, 61%, 58%);
    list-style: none;
    cursor: pointer;
    font-weight: 500
}

.menuBtnList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 10px;
    padding: 0;
    margin-bottom: 0;
    
}

.logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 5px;
    left: 10px;
    border: none;
    width: 40px;
    height: auto;
    cursor: pointer;
    color: hsl(36, 61%, 58%);
  }

  .homeTitle {
    font-size: 20px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: white;
      /* box-shadow: 0 0 10px rgba(255, 223, 0, 0.8);  */
      background: linear-gradient(180deg, gold, black);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-stroke-width: .4px;
      background-clip: text;
      position: absolute;
    top: 5px;
    left: 10px;
    margin-left: 5px;
    cursor: pointer;
}

@media screen and (min-width: 945px) {
    body {
        margin-top: 75px
    }

    .homeTitle {
        font-size: 30px;
    }

    .Header {
        height: 70px;
        display: flex;
        flex-direction: row;
    }
    
    .headerBtnList {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      margin-right: 10px;
    }

    .headerBtn {
        cursor: pointer;
    }
    
    .logo {
        width: 55px;
        height: auto;
    }

    .menuIcon {
        display: none;
    }

    .dropDown {
        display: none;
    }

}

@media screen and (min-width: 1200px) {
    .headerBtnList {
        font-size: 20px;
    }

    .dropDown {
        display: none;
    }
}