.overview {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  overflow-x: hidden;
  position: relative;
}

.logoHome {
  /* background-image: url('../../Images/landing.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100%;
}

.logoOverlay {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio for responsive video */
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1000;
}

.logoImgDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 17px;
  z-index: 1;
}

.logoImg {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: auto;
  width: 180px;
  transform: rotate(-3.9deg);
  clip-path: circle();
}

.logoHome {
  /* background-image:url('../../Images/landing.jpg') ; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  width: 100%;
  /* height: 100vh; Remove this line */
  /* overflow-y: auto; */
  overflow-x: hidden;
}

.logoOverlay {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* height: 100vh; Remove this line */
  overflow: hidden;
}

.home-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.homeDeet {
  font-weight: bold;
  text-align: center;
  color: white;
  text-shadow: 17px 17px 17px black;
}

.soc-rev {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.socialsDeet {
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
}

.reviewsDeet {
  font-weight: bold;
  text-align: center;
  color: white;
  text-shadow: 15px 15px 15px black;
}

.bookBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 85px;
  height: 35px;
  border-radius: 8px;
  background-color: black;
  color: gold;
  font-weight: bold;
  box-shadow: 1px 1px 1px black;
}

.bookBtn:hover {
  background-color: hsl(36, 61%, 58%);
  color: black;
}

.socialsIcons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
}

.socialsIcon {
  height: 50px;
  width: auto;
  cursor: pointer;
  background-color: white;
  clip-path: circle(38%);
}

.reviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 200px;
}

.reviewsDeet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.reviewsIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: auto;
  height: 130px;
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .homeDeet {
    font-size: 20px;
  }

  .socialsDeet {
    font-size: 20px;
  }

  .reviewsDeet {
    font-size: 20px;
  }
}

@media screen and (min-width: 945px) {
  .bookBtn {
    width: 120px;
    height: 40px;
    font-size: 16px;
  }

  .homeDeet {
    font-size: 25px;
  }

  .socialsDeet {
    font-size: 25px;
  }

  .reviewsDeet {
    font-size: 25px;
    margin: 25px 0 10px 0;
  }

  .socialsIcon {
    height: 70px;
  }

  .socials {
    justify-content: flex-start;
    
  }

  .reviews {
    width: 40%;
    justify-content: flex-start;
  }

  .soc-rev {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 80%;
  }

  .socialsDeet, .reviewsDeet {
    text-align: center;
  }
}

@media screen and (min-width: 1200px) {
  .bookBtn {
    width: 150px;
    height: 40px;
    font-size: 20px;
  }

  .logoImg {
    width: 240px;
  }

  .homeDeet,
  .socialsDeet,
  .reviewsDeet {
    font-size: 30px;
  }
  .reviewsDeet {
    margin-top: 35px;
  }
}

@media screen and (min-width: 1400px) {
  .homeDeet {
    width: 80%;
  }

  .soc-rev {
    width: 50%;
  }

}
