.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* min-height: 100vh; */
    width: 100%;
    margin-bottom:20px;
    overflow-x: hidden;
}

.gallery-loading {
  font-weight: bold
}

.gallery-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.gallery-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70%;
  width: 100%;
  
}

.gallery-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gallery-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.prev-pic {
    order: -1;
    cursor: pointer ;
  }
  
  .next-pic {
    order: 1;
    cursor: pointer;
  }

  .thumbnail-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .thumbnail {
    display: flex;
    justify-content: space-evenly;
    width: 25%;
    box-sizing: border-box; 
    padding: 5px; 
    cursor: pointer;
  }

  .thumbnail-img {
    display: block;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 50%;
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    object-fit: cover;
  }

  @media screen and (min-width: 600px) {

    .gallery-img {
        width: 300px;
        height: 300px;
    }

    .thumbnail-row {
        width: 60%;
    }

    .thumbnail-img {
        width: 60px;
        height: 60px;
    }
    
  }

  @media screen and (min-width: 800px) {

    .gallery-div {
      flex-direction: row; 
      /* margin-top: 25px; */
  }

  .gallery-img {
    width: 450px;
    height: 400px;
}

  .thumbnail-row {
      width: auto; 
  }

  .thumbnail {
      width: 25%; 
  }

  .thumbnail-img {
      width: 70px; 
  }
    
  }

  

   @media screen and (min-width: 945px) {

    .gallery-div {
        flex-direction: row; 
        /* margin-top: 25px; */
    }

    .gallery-loading {
      font-size: 20px;
    }

    .gallery-container {
        margin-right: 20px; 
    }

    .gallery-img {
      width: 500px;
      height: 450px;
  }

    .thumbnail-row {
        width: auto; 

    }

    .thumbnail {
        width: 25%; 
    }

    .thumbnail-img {
        width: 100%; 
    }

}

@media screen and (min-width: 1200px) {

    .gallery-container{
      margin-right: 0px;

    }

    .gallery-title {
      font-size: 2.5rem;
    }

    .gallery-img {
        width: 600px;
        height: 470px;
    }

    .thumbnail-img {
        width: 100px;
        height: 100px;
    }

    .prev-pic {
      height: 40px;
    }

    .next-pic {
      height: 40px;
    }

}

@media screen and (min-width: 1380px) {
  .gallery-img {
    width: 700px;
    height: 500px;
  }
}

@media screen and (min-width: 1580px) {
  .gallery-img {
    width: 800px;
    height: 650px;
  }

  .thumbnail-row {
    width: 30%;
  }
}
