.testimonials-overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: black;
    border-radius: 8px;
    border: 2px solid hsl(36, 61%, 58%);
    /* overflow-y: auto; */
    overflow-x: hidden;
}

.slider-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
}

.testimonial-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: hsl(36, 61%, 58%);
    margin: 0;
    margin-bottom: 25px
}

.quote-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: hsl(36, 61%, 58%);
    margin-left: 7px;
}

.slick-prev, .slick-next {
    background-color: black;
    display: none !important;
}

  
  .prev {
    order: -1;
    cursor: pointer ;
    align-self: center;
  }
  
  .next {
    /* padding-left: 5px; */
    order: 1;
    cursor: pointer;
  }

  

.slider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
    border: 2px solid hsl(36, 61%, 58%);
    border-radius: 8px;
    background-color: black;
    cursor: pointer;
}

.testimonial-name {
    color:hsl(36, 61%, 58%);
    margin-left: 7px;
    font-weight: 900
}

.testimonial-text {
    color:hsl(36, 61%, 58%);
    margin-left: 7px;
    margin-right: 5px;
    font-style: italic;
}

.stars-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    flex: 1;
    margin: 0;
}

.review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.stars {
    margin-top: 10px;
}

.slick-dots li button::before{
    color: white;
    opacity: 1;
}

.slick-dots li.slick-active button::before{
    color: hsl(36, 61%, 58%)
}

@media screen and (min-width: 600px) {
    .slider {
        width: 70%;
    }

    .testimonial-name {
        font-size: 18px;
    }

    .testimonial-text {
        font-size: 18px;
    }
}

@media screen and (min-width: 945px) {
.testimonials-overview{
    width: 50%;
    border-radius: 8px;
    border: 2px solid hsl(36, 61%, 58%);
}

.testimonial-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.slider-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

    .slider {
        align-self: center;
        width: 85%;
    }

    .stars-div {
        height: 35%
    }

    .testimonial-name {
        font-size: 20px;
    }

    .testimonial-text {
        font-size: 20px;
    }
}

@media screen and (min-width: 1200px) {

    .quote-icon {
        font-size: 20px
    }

}