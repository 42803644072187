html, body {
    overscroll-behavior: none;
}

*{
    font: 'Roboto', sans-serif;
}

.App {
    height: 100vh;
    width: 100%;
    
}

@media (min-width: 945px) {
    .service-test {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}