.bookings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
    background-image:url('../../Images/landing.jpg') ;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

  .bookings-overlay {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* overflow-y: auto;
    overflow-x: hidden; */
  }

.loading {
    color: white;
    font-weight: bold;
}

.bookings-title {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 0 10px black;
      letter-spacing: 2px;
      color: white;
      /* box-shadow: 0 0 10px rgba(255, 223, 0, 0.8);  */
      background: linear-gradient(180deg, white, gold);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-stroke-width: .4px;
      background-clip: text;
}

.bookings-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-style: italic;
    font-weight: 600;
    margin-top: 0;
    color: white
}

.create-form{
    display: flex;
    flex-direction: column;
    background: black;
    border: 2px solid hsl(36, 61%, 58%);
    border-radius: 8px;
    width: 80%;
    justify-content: flex-start;
    align-items: center;
    min-height: 100%;
}

.create-label{
    color: hsl(36, 61%, 58%);
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 5px;
}

.create-input  {
    margin-bottom: 5px;
    width: 169.6px;
    box-sizing: border-box;
}

.create-input-barber {
    margin-bottom: 5px;
    width: 169.6px;
}

.create-input-time {
    margin-bottom: 25px;
    width: 169.6px;
}

.submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 85px;
    height: 35px;
    border-radius: 8px;
    background-color: black;
    color: gold;
    font-weight: bold;
    box-shadow: 1px 1px 1px black;
    margin-bottom: 8px;
}

.submit-btn:hover {
    background-color: hsl(36, 61%, 58%);
    color: black;
}

@media screen and (min-width: 600px) {
   
    .bookings-text{
        font-size: 18px;
        width: 80%;
    }

    .create-label {
        font-size: 18px;
    }

    .create-input {
        font-size: 18px;
        width: 226.4px;
        box-sizing: border-box;
    }

    .create-input-barber {
        font-size: 18px;
        width: 226.4px;
    }

    .create-input-time {
        font-size: 18px;
        width: 226.4px;
    }

    .submit-btn {
        font-size: 18px;
    }
}

@media screen and (min-width: 760px) {
    .create-form {
        width: 50%;
    }
}

@media screen and (min-width: 945px) {
    .bookings-text{
        font-size: 20px;
        width: 70%;
    }

    .loading {
        font-size: 20px;
    }

    .create-label {
        font-size: 20px;
    }

    .create-input {
        font-size: 20px;
        width: 258.4px;
        box-sizing: border-box;
    }

    .create-input-barber {
        font-size: 20px;
        width: 258.4px;
    }

    .create-input-time {
        font-size: 20px;
        width: 258.4px;
        
    }

    .submit-btn {
        font-size: 20px;
        width: 120px;
        height: 40px;
    }
}

@media screen and (min-width: 1200px) {
    .bookings-text{
        width: 60%;
    }

    .create-form {
        width: 30%;
    }
}