.edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.edit-overlay {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.1); /* Adjust the alpha value to control the darkness of the backdrop */
    backdrop-filter: blur(8px); 
    z-index: 1;
    position: fixed;
    top: 0;
}

.edit-title {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 0 10px black;
      letter-spacing: 2px;
      color: white;
      /* box-shadow: 0 0 10px rgba(255, 223, 0, 0.8);  */
      background: linear-gradient(180deg, white, gold);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-stroke-width: .4px;
      background-clip: text;
      font-size: 25px;
}



.edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 125px;
    height: 35px;
    border-radius: 8px;
    background-color: black;
    color: gold;
    font-weight: bold;
    box-shadow: 1px 1px 1px black;
    margin-bottom: 8px;
}

.edit-btn:hover {
    background-color: hsl(36, 61%, 58%);
    color: black;
}


.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
}

.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    border: 1px solid hsl(36, 61%, 58%);
    border-radius: 8px;
    width: 80%;
    min-height: 50%;
}

.close {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: red;
    font-weight: bold;
    cursor: pointer;
    font-size: 25px;
    position: relative;
    top: 0;
    right: 0;
}

.edit-form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-top: 20px;
}

.edit-input {
    margin-bottom: 5px;
    width: 169.6px;
    box-sizing: border-box;
}

.edit-input-time {
    margin-bottom: 15px;
    width: 169.6px;
}

.label-edit {
    color: hsl(36, 61%, 58%);
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
}

.update-btn {
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 125px;
    height: 35px;
    border-radius: 8px;
    background-color: black;
    color: gold;
    font-weight: bold;
    box-shadow: 1px 1px 1px black;
}

.update-btn:hover {
    background-color: hsl(36, 61%, 58%);
    color: black;
}

@media screen and (min-width: 600px) {

    .edit-input {
        font-size: 18px;
        width: 226.4px;
        box-sizing: border-box;
    }

    .label-edit {
        font-size: 18px;
    }

    .update-btn {
        font-size: 18px;
        width: 180px
    }

    .edit-input-time {
        font-size: 18px;
        width: 226.4px;
    }

    .edit-btn {
        font-size: 18px;
        width: 150px;
    }
}

@media screen and (min-width: 760px) {
    .modal-content {
        width: 50%;
    }
}

@media screen and (min-width: 945px) {

    .edit-input {
        font-size: 20px;
        width: 258.4px;
        box-sizing: border-box;
    }

    .label-edit {
        font-size: 20px;
    }

    .update-btn {
        font-size: 20px;
        width: 180px;
        height: 40px;
    }

    .edit-input-time {
        font-size: 20px;
        width: 258.4px;
    }

    .edit-btn {
        font-size: 20px;
        width: 175px;
        height: 45px
    }
}

@media screen and (min-width: 1200px) {
    .modal-content {
        width: 30%;
    }
}