.team-overview{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.team-container{}

.team-title{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.team-heading{
    font-size: 2rem;
    text-transform: uppercase;
}

.team {
    display: flex;
    justify-content: center; 
    gap: 20px; 
    width: 100%;
}

.team-member {
    display: flex;
    flex-direction: column; 
    align-items: center; 
}


.team-img{
    clip-path: circle();
    width: 150px;
    height: 200px;
    object-fit: cover;
    object-position: center 10%;  
}

.team-name{
    font-size: 20px;
}

.team-desc{
    text-align: center; 
    width: 80%;
    margin: 0 auto;
}

@media screen and (min-width: 600px) {

.team{
    gap: 50px;
}

.team-img{
    width: 200px;
    height: 250px;
}

.team-desc{
    font-size: 18px;
}
}

@media screen and (min-width: 945px) {

.team{
    gap: 100px;
}
.team-img{
    width: 250px;
    height: 300px;
}

.team-desc{
    font-size: 20px;
}

}

@media screen and (min-width: 1200px) {

.team{
    gap: 150px;
}

.team-heading{
    font-size: 2.5rem;
}

.team-img{
    width: 300px;
    height: 350px;
}

.team-name{
    font-size: 28px;
}

.team-desc{
    width: 60%;
}

}