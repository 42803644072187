body {
    margin: 0;
}

.contactOverview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    /* color: gold;
    background-color: black; */
}

.contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.contactSub {
    /* font-size: 13px; */
    margin-bottom: 5px;
}

.address-title {
    margin-top: 0;
}

.contactDeet {
    margin-top: 0px;
    margin-bottom: 5px;
    /* font-size: 12px; */
}

.openingHours {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}



.openingDeet {
    /* font-size: 12px; */
    margin-top: 0;
}

.openingDeet-mobile {
    font-weight: 600;
    /* font-size: 12px; */
    width: 80%;
    text-align: center;
}

.callBtn {
    /* border-radius: 6px;
    background-color: black;
    color: gold;
    margin-bottom: 5px; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 85px;
    height: 35px;
    border-radius: 8px;
    background-color: black;
    color: gold;
    font-weight: bold;
    box-shadow: 1px 1px 1px black;
    margin-bottom: 5px;
}

.callBtn:hover {
    background-color: hsl(36, 61%, 58%);
    color: black;
}

.emailBtn {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 85px;
    height: 35px;
    border-radius: 8px;
    background-color: black;
    color: gold;
    font-weight: bold;
    box-shadow: 1px 1px 1px black;
}

.emailBtn:hover {
    background-color: hsl(36, 61%, 58%);
    color: black;
}

.map {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    /* border: 1px solid red; */
}

.directions {
    /* font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 6px;
    background-color: black;
    color: gold;
    cursor: pointer; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 150px;
    height: 35px;
    border-radius: 8px;
    background-color: black;
    color: gold;
    font-weight: bold;
    box-shadow: 1px 1px 1px black;
}

.directions:hover {
    background-color: hsl(36, 61%, 58%);
    color: black;
}

.directionsIcon {
    margin-left: 10px;
}

.modal, .modal-overlay {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;
}

.modal-overlay {
    background: rgba(49,49,49,0.8);
    display: flex;
    justify-content: center;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    margin-top: 180px;
    border: 1px solid red;
    background-color: white;
    border-radius: 8px;
    position: relative;
}

.contact-name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: 700;
    width: 80%;
}

.contact-email {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
    font-weight: 700;
    width: 80%;
}

.contact-message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
    font-weight: 700;
    width:80%;
    
}

.user-name {
    border-radius: 8px;
    height: 30px;
    width: 80%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.user-email {
    border-radius: 8px;
    height: 30px;
    width: 80%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.user-message {
    border-radius: 8px;
    height: 100px;
    width: 80%;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.contact-send {
    border-radius: 8px;
    height: 30px;
    width: 100px;
    margin-bottom: 10px;
    cursor: pointer;
}

.closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: white;
    color: red;
    font-size: 18px;
}

@media screen and (min-width: 600px) {
    .contactContainer {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }

    .contactInfo, .address-container, .openingHours {
        width: 100%
    }

    .contactInfo, .address-container, .openingHours {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 100%;
        margin-left: 12px;
        margin-right: 12px;
    }

    .address-title{
        margin-top: 0;
    }

    .contactTitle {
        margin-top: 0;
    }

    .openingTitle {
        margin-top: 0;
    }

    .map {
        width: 90%;
    }

    .contactSub {
        font-size: 18px;
    }

    .contactDeet {
        font-size: 18px;
    }
    
    .openingDeet {
        font-size: 18px;
    }

    .openingDeet-mobile {
        font-size: 18px;
    }
}

@media screen and (min-width: 945px) {
    .map {
        width: 70%
    }

    .contactSub {
        font-size: 20px;
    }

    .contactDeet {
        font-size: 20px;
    }

    .callBtn {
        font-size: 15px;
    }

    .emailBtn {
        font-size: 15px;
    }

    .directions {
        font-size: 15px;
    }

    .openingDeet {
        font-size: 20px;
    }

    .openingDeet-mobile {
        font-size: 20px;
    }

    .map {
        height: 300px;
        width: 80%;
        height: 80%;
    }
    
}

@media screen and (min-width: 1200px) {

    .contactContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 90%;
      }

    .openingDeet-mobile {
        width: 100%
    }
}